.services-brief {
	background-image: linear-gradient(180deg,#ff16ce3b,#270547,#270547 50%,#ff16ce3b);

	#services {
		scroll-margin-top: 7em;
	}

	.section-inner {
		padding-top: $features-split--padding-t__mobile;
		padding-bottom: $features-split--padding-b__mobile;
	}

	.section-header {
		padding-bottom: $features-split-header--padding__mobile;
	}

	.split-item-image {
		img {
			border-radius: 10px;
		}
	}
}

@include media( '>medium' ) {

	.services-brief {

		.section-inner {
			padding-top: $features-split--padding-t__desktop;
			padding-bottom: $features-split--padding-b__desktop;
		}

		.section-header {
			padding-bottom: $features-split-header--padding__desktop;
		}
	}
}
