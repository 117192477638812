.contact-form {
	padding-top: 80px;
	padding-bottom: 80px;

	.form-col {
		form {
			padding: 10px 30px;
			background-color: #2e2141;
			border-radius: 10px;
			box-shadow: 0 0px 10px 0 rgb(252 20 205 / 37%);

			.form-group {
				display: block;
				padding: 10px;
		
				input,
				textarea {
					background-color: #2e2141;
					border: solid 1px #fc14cd;
					font-size: 16px;
					color: white;
				}
			}
		}
	}
}

input:focus {
    outline: none !important;
}