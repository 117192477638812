.clients {
	padding-bottom: 80px;
	
	.logo-box {
		border-radius: 10px;
		height: 100%;
		padding: 10px 0px;

		img {
			height: auto;
			width: auto;
			margin: auto;
			// box-shadow: 6px 6px 3px 0 rgb(252 20 205 / 37%);
			background: linear-gradient(90deg, rgb(166, 107, 255) 0%, rgb(198, 102, 239) 50%, rgb(255, 22, 206) 100%);
		}
	}
}