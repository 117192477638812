.leaders {
	padding-bottom: 80px;
	
	#jiang-bin,
	#ahnaf-prio {
		scroll-margin-top: 9em;
	}

	.leader-box {
		padding: 30px 20px;
		background-color: #2e2141;
		border-radius: 10px;
		border-bottom: solid #fc14cd;
		margin: 0px 0px;
		margin-top: 30px;
		box-shadow: 0 3px 10px 0 rgb(252 20 205 / 37%);

		.face {
			// width: 30%;
			img {
				width: 100px;
				height: 100px;
				border-radius: 100%;
				border: solid #fc14cd;
				background: linear-gradient(90deg, rgb(166, 107, 255) 0%, rgb(198, 102, 239) 50%, rgb(255, 22, 206) 100%);
			}
		}

		.description {
			// width: 70%;
			color: white;
			margin-left: 20px;
			width: calc(100% - 100px);
		}
	}
}