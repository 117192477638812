.summary {
	padding-bottom: 80px;
	
	.desc-box {
		padding: 30px 20px;
		background-color: #2e2141;
		border-radius: 10px;
		height: 100%;
		border-bottom: solid #fc14cd;
		box-shadow: 0 3px 10px 0 rgb(252 20 205 / 37%);
	}
}